import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css"; // Default styling
import NavBar from "./components/NavBar";
import ProgressBar from "./components/ProgressBar";
import "./styles/PastMeals.css";
import { getRecordedMealsForDate, getUserNutritionTarget } from "../firebase";

const PastMeals = () => {
  const [date, setDate] = useState(new Date());
  const [viewMode, setViewMode] = useState("daily");
  const [breakfast, setBreakfast] = useState({});
  const [lunch, setLunch] = useState({});
  const [dinner, setDinner] = useState({});
  const [calories, setCalories] = useState(0);
  const [protein, setProtein] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [targetCalories, setTargetCalories] = useState(2000); // Default values
  const [targetProtein, setTargetProtein] = useState(144);
  const [targetCarbs, setTargetCarbs] = useState(300);
  const [targetFat, setTargetFat] = useState(60);

  async function fetchMeals(formattedDate) {
    let meals = await getRecordedMealsForDate(formattedDate);
    console.log(meals, date, formattedDate)
    if (meals === null) { // no meal for the date
      setCalories(0);
      setProtein(0);
      setCarbs(0);
      setFat(0);
      return;
    }
    setBreakfast(meals.breakfast ? meals.breakfast : {});
    setLunch(meals.lunch ? meals.lunch : {});
    setDinner(meals.dinner ? meals.dinner : {});

    // Calculate total calories, protein, carbs, and fat
    let totalCalories = 0;
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;

    Object.values(meals).forEach((meal) => {
      Object.values(meal).forEach((item) => {
        Object.values(item.nutrition).forEach((nutritionListItem) => {
          if (nutritionListItem.elementName === "ENERGY (KCAL)") {
            // need terinary operator to handle imparsible values
            totalCalories += parseFloat(nutritionListItem.displayAmount)
              ? parseFloat(nutritionListItem.displayAmount)
              : 0;
          } else if (nutritionListItem.elementName === "TOTAL LIPID (FAT)") {
            totalFat += parseFloat(nutritionListItem.displayAmount)
              ? parseFloat(nutritionListItem.displayAmount)
              : 0;
          } else if (nutritionListItem.elementName === "PROTEIN") {
            totalProtein += parseFloat(nutritionListItem.displayAmount)
              ? parseFloat(nutritionListItem.displayAmount)
              : 0;
          } else if (
            nutritionListItem.elementName === "CARBOHYDRATE BY DIFFERENCE"
          ) {
            totalCarbs += parseFloat(nutritionListItem.displayAmount)
              ? parseFloat(nutritionListItem.displayAmount)
              : 0;
          }
        });
      });
    });
    setCalories(totalCalories);
    setProtein(totalProtein);
    setCarbs(totalCarbs);
    setFat(totalFat);

    let nutritionTarget = await getUserNutritionTarget();
    setTargetCalories(nutritionTarget.targetCalories || targetCalories);
    setTargetProtein(nutritionTarget.targetProtein || targetProtein);
    setTargetCarbs(nutritionTarget.targetCarbs || targetCarbs);
    setTargetFat(nutritionTarget.targetFat || targetFat);
  }

  useEffect(() => {
    console.log("update info");
    let formattedDate = date
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");
    fetchMeals(formattedDate);
  }, [date]);

  const formatDate = (date) => {
    // convert date to format "MM/DD"
    return date.toLocaleDateString("en-US").slice(0, -5);
  };

  const handleModeChange = (mode) => {
    setViewMode(mode);
  };

  const handleDateChange = (direction) => {
    const newDate = new Date(date);
    if (direction === "left") {
      newDate.setDate(newDate.getDate() - 1);
    } else {
      newDate.setDate(newDate.getDate() + 1);
    }
    setDate(newDate);
  };

  return (
    <div>
      <NavBar />
      <div className="past-meals-container">
        <div className="top-row">
          <h2>My Nutrition Goal</h2>
          <div className="button-container">
            <span className="date-selector">
              <button
                className="left-button"
                onClick={() => handleDateChange("left")}
              >
                {"<"}
              </button>
              <p>{formatDate(date)}</p>
              <button
                className="right-button"
                onClick={() => handleDateChange("right")}
              >
                {">"}
              </button>
            </span>
            <span className="view-mode-selector">
              <button
                className="left-button"
                onClick={() => handleModeChange("daily")}
              >
                daily
              </button>
              <button
                className="right-button"
                onClick={() => handleModeChange("monthly")}
              >
                monthly
              </button>
            </span>
          </div>
        </div>
        {viewMode === "daily" ? (
          <div className="progress-bars">
            {/* TODO: NOT hardcode */}
            <ProgressBar
              label="Calories"
              value={calories}
              max={targetCalories}
            />
            <ProgressBar label="Protein" value={protein} max={targetProtein} />
            <ProgressBar label="Carbs" value={carbs} max={targetCarbs} />
            <ProgressBar label="Fat" value={fat} max={targetFat} />
          </div>
        ) : (
          <div className="calendar-view">
            {/* FIXME: bug */}
            {/* <Calendar onChange={setTest} value={test} /> */}
          </div>
        )}

        <div className="middle-row">
          <Link to="/profile" className="underscore-italic">
            Modify My Nutrition Goal
          </Link>
        </div>
        <div className="bottom-row">
          <details className="meal-dropdown">
            <summary>Breakfast - {formatDate(date)}</summary>
            <ul>
              {Object.values(breakfast).map((item, index) => (
                <li key={item.itemName}>
                  {item.itemName} - {item.quantity} x {item.portionSize}
                </li>
              ))}
            </ul>
          </details>
          <details className="meal-dropdown">
            {/* TODO: NOT HARD CODE */}
            <summary>Lunch - {formatDate(date)}</summary>
            <ul>
              {Object.values(lunch).map((item, _) => (
                <li key={item.itemName}>
                  {item.itemName} - {item.quantity} x {item.portionSize}
                </li>
              ))}
            </ul>
          </details>
          <details className="meal-dropdown">
            <summary>Dinner - {formatDate(date)}</summary>
            <ul>
              {Object.values(dinner).map((item, _) => (
                <li key={item.itemName}>
                  {item.itemName} - {item.quantity} x {item.portionSize}
                </li>
              ))}
            </ul>
          </details>
        </div>
      </div>
    </div>
  );
};

export default PastMeals;