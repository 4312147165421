import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
// import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getFirestore, collection, doc, setDoc, query, where, getDoc, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
  
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth();
export const googleAuthProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleAuthProvider);
    return result.user;
  } catch (error) {
    console.error("Error during Google sign in", error);
    return null;
  }
};

export const signOut = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error during sign out", error);
  }
};

export const checkUserExistence = async (email) => {
  try {
    const useRef = collection(db, "users");
    const q = query(useRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length > 0;
  } catch (err) {
    console.error("Error checking if user exists", err);
    return null;
  }
};

export async function registerUser(uid, userData) {
  try {
    await setDoc(doc(db, 'users', uid), userData);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const isUserLoggedIn = () => {
  return auth.currentUser !== null;
};

export async function getUserInfo() {
  if (auth.currentUser.uid == null) return null;
  try {
    const docRef = doc(db, 'users', auth.currentUser?.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      return {
        email: userData.email, 
        name: userData.name,
        age: userData.age,
        height: userData.height,
        heightUnit: userData.heightUnit,
        weight: userData.weight,
        weightUnit: userData.weight,
        activityLevel: userData.activityLevel,
      };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
}

// add cart items to user's entry in firebase.
// the function takes in the user's uid, the cart items, meal type, and date
export async function addCartToFirebase(cartItems, mealType, date) {
  try {
    const userRef = doc(db, 'users', auth.currentUser?.uid);
    const userDoc = await getDoc(userRef);
    // FIXME: delete later
    console.log("adding cart to firebase now")
    console.log("User doc exists: ", userDoc.exists())

    if (userDoc.exists()) {
      const userCart = userDoc.data().cart || {};
      userCart[date] = userCart[date] || {};
      userCart[date][mealType] = cartItems;
      console.log('userCart', userCart);
      await setDoc(userRef, { cart: userCart }, { merge: true });
    }
  } catch (error) {
    console.error('Error adding cart items to firebase:', error);
  }
}

// get all items for a user on a specific date
export async function getRecordedMealsForDate(date) {
  try {
    const userRef = doc(db, 'users', auth.currentUser?.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userCart = userDoc.data().cart || {};
      return userCart[date] || {};
    }
  } catch (error) {
    console.error('Error getting cart items from firebase:', error);
    return null;
  }
}

export async function getUserNutritionTarget(uid=auth.currentUser?.uid) {
  try {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      return {
        targetCalories: userData.targetCalories || 0,
        targetProtein: userData.targetProtein || 0,
        targetCarbs: userData.targetCarbs || 0,
        targetFat: userData.targetFat || 0,
        targetDeficitSurplus: userData.targetDeficitSurplus || 0,
        goal: userData.goal || "lose",
      };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
}

export async function updateUserNutritionTarget(calories, protein, carbs, fat) {
  try {
    const userRef = doc(db, 'users', auth.currentUser?.uid);
    await setDoc(userRef, {
      targetCalories: calories,
      targetProtein: protein,
      targetCarbs: carbs,
      targetFat: fat
    }, { merge: true });
  } catch (error) {
    console.error('Error updating user nutrition target:', error);
  }
}


export async function uploadUserSignupInfo(formData) {
  try {
    const userRef = doc(db, 'users', auth.currentUser?.uid);
    await setDoc(userRef, formData, { merge: true });
  } catch (error) {
    console.error('Error uploading user signup info:', error);
  }
}

export async function getUserDiningInfo(uid=auth.currentUser?.uid) {
  try {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      return {
        allergens: userData.allergens || [],
        mealPreferences: userData.mealPreferences || {
          Monday: { Breakfast: "", Lunch: "", Dinner: "" },
          Tuesday: { Breakfast: "", Lunch: "", Dinner: "" },
          Wednesday: { Breakfast: "", Lunch: "", Dinner: "" },
          Thursday: { Breakfast: "", Lunch: "", Dinner: "" },
          Friday: { Breakfast: "", Lunch: "", Dinner: "" },
          Saturday: { Breakfast: "", Lunch: "", Dinner: "" },
          Sunday: { Breakfast: "", Lunch: "", Dinner: "" },
        },
      };
    } else {
      console.log('No such document!');
      return [];
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
}

export async function updateUserDiningInfo(allergens, mealPreferences) {
  try {
    const userRef = doc(db, 'users', auth.currentUser?.uid);
    await setDoc(userRef, {
      allergens: allergens,
      mealPreferences: mealPreferences
    }, { merge: true });
  } catch (error) {
    console.error('Error updating user dining info:', error);
  }
}