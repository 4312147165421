import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "./components/NavBar";
import Background from "./components/Background";
import RecPopup from "./components/RecPopup";
import MealTracking from "./components/MealTracking";
import "./styles/Home.css";
import axios from "axios";
import { updateUserNutritionTarget, getUserNutritionTarget, getUserInfo, getUserDiningInfo } from "../firebase";
import { set } from "firebase/database";

const Home = () => {
  const [showRecPopup, setShowRecPopup] = useState(false);
  const [showMealTrackPopup, setShowMealTrackPopup] = useState(false);
  const [selections, setSelections] = useState({
    foodType: "anything",
    location: "Zeppos",
    mealTime: "dinner",
    date: new Date().toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-'), // Default to today's date in YYYY-MM-DD format
  });

  const [age, setAge] = useState(20);
  const [gender, setGender] = useState('male');
  const [weight, setWeight] = useState(55);
  const [height, setHeight] = useState(175);
  const [goal, setGoal] = useState('lose_weight');
  const [targetDeficit, setTargetDeficit] = useState(500)
  const [activityLevel, setActivityLevel] = useState('lightly');
  const [mealType, setMealType] = useState('dinner');
  const [location, setLocation] = useState('Zeppos');
  const [targetCalories, setTargetCalories] = useState(0);
  const [targetProtein, setTargetProtein] = useState(0);
  const [targetFat, setTargetFat] = useState(0);
  const [targetCarbs, setTargetCarbs] = useState(0);
  const [allergens, setAllergens] = useState([]);

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [recommendations, setRecommendations] = useState({
    daily_calories: 0,
    target_deficit: 0,
    calories_per_meal: 0,
    deficit_per_meal: 0,
    final_calories: 0,
    final_protein: 0,
    final_fat: 0,
    final_carbs: 0,
    recommended_items: []
  });

  useEffect(() => {
    // Fetch user data from Firebase when the component mounts
    const fetchUserData = async () => {
      try {
        const userInfo = await getUserInfo();
        const userData = await getUserNutritionTarget();
        const userDiningInfo = await getUserDiningInfo();
        console.log(userInfo)
        console.log(userData)
        console.log(userDiningInfo)
        // Assuming userInfo object has age, gender, etc., structured correctly
        setAge(userInfo.age);
        setGender(userInfo.gender);
        // If the weight unit is in pounds, convert it to kg
        if (userInfo.weightUnit === 'lbs') {
          setWeight(userInfo.weight * 0.453592);
        } else {
          setWeight(userInfo.weight);
        }

        // If the height unit is in inches, convert it to cm
        if (userInfo.heightUnit === 'inches') {
          setHeight(userInfo.height * 2.54);
        } else {
          setHeight(userInfo.height);
        }

        setGoal(userData.goal);
        // Set the target deficit as (-1) * userData.targetDeficitSurplus
        setTargetDeficit(-1 * userData.targetDeficitSurplus);
        setActivityLevel(userInfo.activityLevel);
        setTargetCalories(userData.targetCalories);
        setTargetProtein(userData.targetProtein);
        setTargetFat(userData.targetFat);
        setTargetCarbs(userData.targetCarbs);
        setAllergens(userDiningInfo.allergens);

        setIsDataLoaded(true);

        // You will have to adjust the code according to the actual structure of your user data
      } catch (error) {
        console.error("There was an error fetching the user info:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      fetchUpdatedRecommendations(); // Call this only after all state updates
      setIsDataLoaded(false); // Reset the flag
    }
  }, [isDataLoaded]);

  const fetchUpdatedRecommendations = () => {
    console.log(weight, height, age)
    console.log(activityLevel)
    axios.get(`http://18.117.233.236:8000/generate-recommendation/`, {
      params: {
        gender: gender,
        age: age,
        weight: weight,
        height: height,
        goal: goal,
        targetDeficit: targetDeficit,
        activityLevel: activityLevel,
        mealType: mealType,
        location: location,
        targetCalories: targetCalories,
        targetProtein: targetProtein,
        targetFat: targetFat,
        targetCarbs: targetCarbs,
        allergens: allergens
      }
    })
    .then(response => {
      setRecommendations(response.data);
      console.log(getUserInfo());
    })
    .catch(error => {
      console.error("There was an error fetching the updated recommendations:", error);
    });
  };

  const handleRecommend = (newSelections) => {
    // console.log("New selections:", newSelections);
    setSelections(newSelections);
    setShowRecPopup(false);
  };

  // Generate an array of dates for the past 7 days and today
  const getDatesArray = () => {
    const today = new Date();
    const dates = [];

    for (let i = 7; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const formattedDate = date.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\//g, '-');
      dates.push(formattedDate);
    }

    return dates;
  };

  return (
    <>
      <div
        className={`home-container ${
          showRecPopup || showMealTrackPopup ? "blur" : ""
        }`}
      >
        <NavBar />
        <Background />
        <div className="top-section">
          <div className="meal-selection-bar">
            <p>
              I had
              <select
                name="mealType"
                className="selector"
                value={selections.mealTime}
                onChange={(e) =>
                  setSelections({ ...selections, mealTime: e.target.value })
                }
              >
                <option value="breakfast">breakfast</option>
                <option value="lunch">lunch</option>
                <option value="dinner">dinner</option>
              </select>
              at
              <select
                name="location"
                className="selector"
                value={selections.location}
                onChange={(e) => {
                  setSelections({ ...selections, location: e.target.value })
                  setLocation(e.target.value)
                }}
              >
                {/* <option value="kissam">Kissam</option>
                <option value="2301">2301</option>
                <option value="rand">Rand</option> */}
                <option value="Zeppos">Zeppos</option>
                <option value="E. Bronson Ingram">EBI</option>
                {/* <option value="commons">Commons</option> */}
                <option value="Rothschild">Rothschild</option>
                {/* <option value="pub">The Pub</option> */}
              </select>
              <select
                name="date"
                className="selector"
                value={selections.date}
                onChange={(e) =>
                  setSelections({ ...selections, date: e.target.value })
                }
              >
                {getDatesArray().map((date) => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
              </select>
              ...
            </p>
            {/* TODO: pass location and which meal to the default value at mealtracking popup*/}
            <button
              onClick={() => setShowMealTrackPopup(true)}
              className="gold-round-button"
            >
              Start Tracking
            </button>
          </div>
        </div>
        <div className="bottom-section">
          <div className="left-column">
            <h2 className="rec-title">
                  {selections.mealTime.charAt(0).toUpperCase() + selections.mealTime.slice(1)} Rec: 
                  <span style={{ color: "var(--color-gold)" }}>
                    {selections.location.toUpperCase()}
                  </span>
            </h2>
            <br></br>
            <div className="image-row">
              <img
                src="/images/MealTrackingBackground.jpg"
                alt="placeholder"
                className="placeholder-image"
              />
            </div>
            <button onClick={fetchUpdatedRecommendations} className="gold-rect-button">
              Refresh Recommendations
            </button>
            <button
              className="underscore-italic alt-btn"
              onClick={() => setShowRecPopup(true)}
            >
              I want something else
            </button>
          </div>
          <div className="right-column">
            {/* Allow the users to select their goals*/}
            {/* <div className = 'selections'>
              Meal Type <select name="mealType" value={mealType} onChange={(e) => setMealType(e.target.value)} >
                <option value="breakfast">Breakfast</option>
                <option value="lunch">Lunch</option>
                <option value="dinner">Dinner</option>
              </select>
              Location <select name="location" value={location} onChange={(e) => setLocation(e.target.value)} >
              <option value="Zeppos">Zeppos</option>
                {/* <option value="kissam">Kissam</option> */}
                {/* <option value="2301">2301</option> */}
                {/* <option value="rand">Rand</option> */}
                {/* <option value="E. Bronson Ingram">EBI</option> */}
                {/* <option value="commons">Commons</option> */}
                {/* <option value="Rothschild">Rothschild</option> */}
                {/* <option value="pub">The Pub</option> */}
              {/* </select> */}
              {/* Gender <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)} >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              Age <input type="number" name="age" value={age} onChange={(e) => setAge(e.target.value)} />
              Weight(kg) <input type="number" name="weight" value={weight} onChange={(e) => setWeight(e.target.value)} />
              Height(cm) <input type="number" name="height" value={height} onChange={(e) => setHeight(e.target.value)} />
              Goal <select name="goal" value={goal} onChange={(e) => setGoal(e.target)} >
                <option value="lose_weight">Lose Weight</option>
                <option value="gain_weight">Gain Weight</option>
                <option value="maintain_weight">Maintain Weight</option>
              </select>
              Target Deficit <input type="number" name="targetDeficit" value={targetDeficit} onChange={(e) => setTargetDeficit(e.target.value)} />
              Activity Level <select name="activityLevel" value={activityLevel} onChange={(e) => setActivityLevel(e.target.value)} >
                <option value="sedentary">Sedentary</option>
                <option value="lightly">Lightly Active</option>
                <option value="moderately">Moderately Active</option>
                <option value="very">Very Active</option>
                <option value="super">Super Active</option>
              </select>
            </div> */}
            <div className="recommend-row">
              {/* This div will contain the recommendations */}
              <div className="recommendations-list">
                <h2 className="rec-title">
                      <span style={{ color: "var(--color-gold)" }}> Recommended Dishes
                      </span>
                </h2>
                {Object.entries(recommendations.recommended_items).map(([courseName, items], index) => (
                  <div key={index}>
                    <h3>{courseName}</h3>
                    <ul>
                      {items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              {/* Display other information like daily_calories, target_deficit, etc., as a table */}
              <div className="recommendations-info">
              <h2 className="rec-title">
                    <span style={{ color: "var(--color-gold)" }}> Nutrition Info
                    </span>
              </h2>
                <table>
                  <tbody>
                    <tr>
                      <td>Daily Calories (Cals)</td>
                      <td>{recommendations.daily_calories}</td>
                    </tr>
                    <tr>
                      <td>Target Deficit (Cals)</td>
                      <td>{recommendations.target_deficit}</td>
                    </tr>
                    <tr>
                      <td>Calories per Meal (Cals)</td>
                      <td>{recommendations.calories_per_meal}</td>
                    </tr>
                    <tr>
                      <td>Deficit per Meal (Cals)</td>
                      <td>{recommendations.deficit_per_meal}</td>
                    </tr>
                    <tr>
                      <td>Final Calories (Cals) </td>
                      <td>{recommendations.final_calories}</td>
                    </tr>
                    <tr>
                      <td>Final Protein (g)</td>
                      <td>{recommendations.final_protein}</td>
                    </tr>
                    <tr>
                      <td>Final Fat (g)</td>
                      <td>{recommendations.final_fat}</td>
                    </tr>
                    <tr>
                      <td>Final Carbs (g) </td>
                      <td>{recommendations.final_carbs}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              
            <div className="action-row">
              {/* FIXME: should not be link, same as start tracking button */}
              <button
                onClick={() => setShowMealTrackPopup(true)}
                className="white-rect-button"
              >
                Edit Meal
              </button>
              {/* TODO: No function for now. Should show nutrition info of displayed recommended meal. Change this to open popup as well */}
              <button className="white-rect-button">Check Nutrition</button>
              <button className="gold-rect-button">
                Add to Tracker
              </button>
            </div>
          </div>
        </div>
      </div>
      {showRecPopup && (
        <RecPopup
          onClose={() => setShowRecPopup(false)}
          onRecommend={handleRecommend}
          selections={selections}
          setSelections={setSelections} // Passing down the setSelections
          fetchUpdatedRecommendations={fetchUpdatedRecommendations}
          // Add more props as necessary for each piece of state you want to update
          setAge={setAge}
          setGender={setGender}
          setWeight={setWeight}
          setHeight={setHeight}
          setGoal={setGoal}
          setTargetDeficit={setTargetDeficit}
          setActivityLevel={setActivityLevel}
          setLocation={setLocation}
          setMealType={setMealType}
        />
      )}
      {showMealTrackPopup && (
        <MealTracking
          showMealTrackPopup={showMealTrackPopup}
          onCloseMealTrackPopup={() => setShowMealTrackPopup(false)}
          selectedDate={selections.date}
          selectedLocation={selections.location}
          selectedMealType={selections.mealTime}
        />
      )}
    </>
  );
};

export default Home;
