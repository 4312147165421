import React, { useState } from "react";
import NavBar from "./components/NavBar";
//import Background from './components/Background';
import "./styles/SignUp.css";
import { uploadUserSignupInfo } from "../firebase.js";
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate();
  const [calories, setCalories] = useState("");
  const [proteins, setProteins] = useState("");
  const [carbs, setCarbs] = useState("");
  const [fats, setFats] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [heightUnit, setHeightUnit] = useState("inches");
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("lbs");
  const [age, setAge] = useState("");
  const [goal, setGoal] = useState("");
  const [activityLevel, setActivityLevel] = useState("");
  const [targetDeficitSurplus, setTargetDeficitSurplus] = useState(0);
  const [diningHall, setDiningHall] = useState("noPreference");
  const [allergens, setAllergens] = useState([]);
  const [diningHallColor, setDiningHallColor] = useState("gray"); // Default color for no preference
  const [mealPreferences, setMealPreferences] = useState({
    Monday: { Breakfast: "", Lunch: "", Dinner: "" },
    Tuesday: { Breakfast: "", Lunch: "", Dinner: "" },
    Wednesday: { Breakfast: "", Lunch: "", Dinner: "" },
    Thursday: { Breakfast: "", Lunch: "", Dinner: "" },
    Friday: { Breakfast: "", Lunch: "", Dinner: "" },
    Saturday: { Breakfast: "", Lunch: "", Dinner: "" },
    Sunday: { Breakfast: "", Lunch: "", Dinner: "" },
  });
  const activity_factor = {'sedentary': 1.2, 'lightlyActive': 1.375, 'moderatelyActive': 1.55, 'veryActive': 1.725, 'superActive': 1.9}

  const calculateCalories = () => {
    if(height && weight && age  && gender && activityLevel) {
      let adjustedWeight = weightUnit === 'lbs' ? weight * 0.454 : weight;
      let adjustedHeight = heightUnit === 'inches' ? height * 2.54 : height;
      let bmr = 0;
      if(gender === 'male'){
        bmr = 66.5 + (13.75 * adjustedWeight) + (5.003 * adjustedHeight) - (6.75 * age)
      } else{
        bmr = 655.1 + (9.563 * adjustedWeight) + (1.850 * adjustedHeight) - (4.676 * age)
      }
      return Math.round(bmr * activity_factor[activityLevel]);
    }
    return 0
  }

  const handleCaloriesChange = (event) => {
    setCalories(event.target.value);
  };

  const handleProteinsChange = (event) => {
    setProteins(event.target.value);
  };

  const handleCarbsChange = (event) => {
    setCarbs(event.target.value);
  };

  const handleFatsChange = (event) => {
    setFats(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleHeightChange = (event) => {
    setHeight(event.target.value);
  };

  const handleHeightUnitChange = (event) => {
    setHeightUnit(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  const handleWeightUnitChange = (event) => {
    setWeightUnit(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleGoalChange = (event) => {
    setGoal(event.target.value);
  };

  const handleActivityLevelChange = (event) => {
    setActivityLevel(event.target.value);
  };

  const handleTargetDeficitSurplusChange = (event) => {
    setTargetDeficitSurplus(event.target.value);
  };

  const handleDiningHallChange = (event) => {
    const selectedHall = event.target.value;
    setDiningHall(selectedHall);
    switch (selectedHall) {
      case "noPref":
        setDiningHallColor("gray");
        break;
      case "rand":
        setDiningHallColor("#FA9189");
        break;
      case "kissam":
        setDiningHallColor("#FCAE7C");
        break;
      case "zeppos":
        setDiningHallColor("#FFE699");
        break;
      case "commons":
        setDiningHallColor("#e2f268");
        break;
      case "ebi":
        setDiningHallColor("#B3F5BC");
        break;
      case "rothschild":
        setDiningHallColor("#D6F6FF");
        break;
      case "2301":
        setDiningHallColor("#E2CBF7");
        break;
      case "thePub":
        setDiningHallColor("#D1BDFF");
        break;
      default:
        setDiningHallColor("gray");
    }
  };

  const lookupDiningHallByColor = (color) => {
    switch (color) {
      case "gray":
        return "No Preference";
      case "#FA9189":
        return "Rand";
      case "#FCAE7C":
        return "Kissam";
      case "#FFE699":
        return "Zeppos";
      case "#e2f268":
        return "Commons";
      case "#B3F5BC":
        return "EBI";
      case "#D6F6FF":
        return "Rothschild";
      case "#E2CBF7":
        return "2301";
      case "#D1BDFF":
        return "The Pub";
      default:
        return "No Preference";
    }
  };

  const handleAllergenChange = (event) => {
    const allergen = event.target.value;
    if (event.target.checked) {
      setAllergens([...allergens, allergen]);
    } else {
      setAllergens(allergens.filter((item) => item !== allergen));
    }
  };

  const handleCellClick = (day, meal) => {
    const updatedPreferences = { ...mealPreferences };
    updatedPreferences[day][meal] = diningHallColor;
    setMealPreferences(updatedPreferences);
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    let h = height;
    let w = weight;
    let hu = heightUnit;
    let wu = weightUnit;
    if(heightUnit === "inches") {
      h = height * 2.54;
      hu = "cm";
    }
    if (weightUnit === "lbs") {
      w = weight * 0.454;
      wu = "kg";
    }
    const formData = {
      targetCalories: calories,
      targetProtein: proteins,
      targetCarbs: carbs,
      targetFat: fats,
      gender: gender,
      height: Math.round(h),
      heightUnit: hu,
      weight: Math.round(w),
      weightUnit: wu,
      age: age,
      goal: goal,
      activityLevel: activityLevel,
      targetDeficitSurplus: targetDeficitSurplus,
      diningHall: diningHall,
      allergens: allergens,
      mealPreferences: mealPreferences,
    };
    uploadUserSignupInfo(formData)
    console.log(formData);
    navigate("/profile");
  };

  return (
    <div className="registration-container">
      <NavBar />
      <section className="section-registration">
        <h2 className="h2-registration">Personal Information</h2>
        <form onSubmit={handleSignUp} className="form-registration">
          <div>
            <label htmlFor="age" className="label-registration">
              Age:
            </label>
            <input
              type="number"
              id="age"
              name="age"
              placeholder="Age"
              value={age}
              className="input-registration"
              onChange={handleAgeChange}
            />
          </div>

          <div>
            <label htmlFor="gender" className="label-registration">
              Biological Gender:
            </label>
            <select
              id="gender"
              value={gender}
              onChange={handleGenderChange}
              className="select-registration"
            >
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div>
            <label htmlFor="height" className="label-registration">
              Height:
            </label>
            <input
              type="number"
              id="height"
              name="height"
              placeholder="Height"
              value={height}
              className="input-registration"
              onChange={handleHeightChange}
            />
            <select
              value={heightUnit}
              onChange={handleHeightUnitChange}
              className="unit-select"
            >
              <option value="inches">inches</option>
              <option value="cm">cm</option>
            </select>
          </div>

          <div>
            <label htmlFor="weight" className="label-registration">
              Weight:
            </label>
            <input
              type="number"
              id="weight"
              name="weight"
              placeholder="Weight"
              className="input-registration"
              value={weight}
              onChange={handleWeightChange}
            />
            <select
              value={weightUnit}
              onChange={handleWeightUnitChange}
              className="unit-select"
            >
              <option value="lbs">lbs</option>
              <option value="kg">kg</option>
            </select>
          </div>
          <div>
            <label htmlFor="activityLevel" className="label-registration">
              Activity Level:
            </label>
            <select
              id="activityLevel"
              value={activityLevel}
              className="select-registration"
              onChange={handleActivityLevelChange}
              select="select-registration"
            >
              <option value="">Select</option>
              <option value="sedentary">Sedentary</option>
              <option value="lightlyActive">Lightly Active</option>
              <option value="moderatelyActive">Moderately Active</option>
              <option value="veryActive">Very Active</option>
              <option value="superActive">Super Active</option>
            </select>
          </div>

          <span>
          <label className="label-registration">
              Suggested Calories Intake:
          </label>
          { calculateCalories() !== 0 ? 
            <p>Your daily caloric need is <strong>{calculateCalories()}</strong> calories</p>
            : <p>Please fill out everything above to get your suggested calorie.</p>}
        </span>
        </form>
      </section>

      <section className="section-registration">
        <h2 className="h2-registration">My Nutrition Goal</h2>
        
        <form>
          <label htmlFor="calories" className="label-registration">
            Calories:
          </label>
          <input
            type="number"
            id="calories"
            name="calories"
            placeholder="Calories"
            value={calories}
            className="input-registration"
            onChange={handleCaloriesChange}
          />
          <label htmlFor="proteins" className="label-registration">
            Proteins:
          </label>
          <input
            type="number"
            id="proteins"
            name="proteins"
            placeholder="Proteins"
            value={proteins}
            className="input-registration"
            onChange={handleProteinsChange}
          />
          <label htmlFor="Carbs" className="label-registration">
            Carbs:
          </label>
          <input
            type="number"
            id="carbs"
            name="carbs"
            placeholder="Carbs"
            value={carbs}
            className="input-registration"
            onChange={handleCarbsChange}
          />
          <label htmlFor="fats" className="label-registration">
            Fats:
          </label>
          <input
            type="number"
            id="fats"
            name="fats"
            placeholder="Fats"
            value={fats}
            className="input-registration"
            onChange={handleFatsChange}
          />

          <div>
            <label htmlFor="goal" className="label-registration">
              Goal:
            </label>
            <select
              className="select-registration"
              id="goal"
              value={goal}
              onChange={handleGoalChange}
            >
              <option value="">Select</option>
              <option value="loseWeight">Lose Weight</option>
              <option value="gainWeight">Gain Weight</option>
              <option value="maintain">Maintain</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="targetDeficitSurplus"
              className="label-registration"
            >
              Target Deficit/Surplus:
            </label>
            <input
              type="number"
              id="targetDeficitSurplus"
              name="targetDeficitSurplus"
              placeholder="Target Deficit/Surplus"
              value={targetDeficitSurplus}
              className="input-registration"
              onChange={handleTargetDeficitSurplusChange}
            />
          </div>
          <p>
            Not sure what it should be? Take a short quiz{" "}
            <a
              href="https://tdeecalculator.net/"
              style={{ color: "gold" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </form>
      </section>

      <section className="section-registration">
        <h2 className="h2-registration">Location and Dietary Preference</h2>
        <label className="label-registration">
          Click on the dining hall below and click on the meal that you want to eat at that dining hall.
        </label>
        <table className="table-registration" id="mealPreferences">
          <thead className="thead-registration">
            <tr className="tr-registration">
              <th className="th-registration"></th>
              <th className="th-registration" style={{ width: "26.67%" }}>
                Breakfast
              </th>
              <th className="th-registration" style={{ width: "26.67%" }}>
                Lunch
              </th>
              <th className="th-registration" style={{ width: "26.67%" }}>
                Dinner
              </th>
            </tr>
          </thead>
          <tbody className="tbody-registration">
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day) => (
              <tr key={day}>
                <td className="td-registration">{day}</td>
                <td
                  onClick={() => handleCellClick(day, "Breakfast")}
                  style={{
                    backgroundColor: mealPreferences[day]["Breakfast"],
                  }}
                  className="td-registration"
                >{lookupDiningHallByColor(mealPreferences[day]["Breakfast"])}</td>
                <td
                  onClick={() => handleCellClick(day, "Lunch")}
                  style={{
                    backgroundColor: mealPreferences[day]["Lunch"],
                  }}
                  className="td-registration"
                >{lookupDiningHallByColor(mealPreferences[day]["Lunch"])}</td>
                <td
                  onClick={() => handleCellClick(day, "Dinner")}
                  style={{
                    backgroundColor: mealPreferences[day]["Dinner"],
                  }}
                  className="td-registration"
                >{lookupDiningHallByColor(mealPreferences[day]["Dinner"])}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <label className="label-registration" htmlFor="diningHall">
          Select Dining Hall:
        </label>
        <div>
          <input
            type="radio"
            id="noPreference"
            name="diningHall"
            className="input-registration"
            value="noPreference"
            checked={diningHall === "noPreference"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="noPreference"
            style={{ color: "gray" }}
            className="diningHall-labels"
          >
            No Preference
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="rand"
            name="diningHall"
            value="rand"
            className="input-registration"
            checked={diningHall === "rand"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="rand"
            style={{ color: `#FA9189` }}
            className="diningHall-labels"
          >
            Rand
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="kissam"
            name="diningHall"
            value="kissam"
            className="input-registration"
            checked={diningHall === "kissam"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="kissam"
            style={{ color: "#FCAE7C" }}
            className="diningHall-labels"
          >
            Kissam
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="zeppos"
            name="diningHall"
            value="zeppos"
            className="input-registration"
            checked={diningHall === "zeppos"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="zeppos"
            style={{ color: "#FFE699" }}
            className="diningHall-labels"
          >
            Zeppos
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="commons"
            name="diningHall"
            value="commons"
            className="input-registration"
            checked={diningHall === "commons"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="commons"
            style={{ color: "#e2f268" }}
            className="diningHall-labels"
          >
            Commons
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="ebi"
            name="diningHall"
            value="ebi"
            className="input-registration"
            checked={diningHall === "ebi"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="ebi"
            style={{ color: "#B3F5BC" }}
            className="diningHall-labels"
          >
            EBI
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="rothschild"
            name="diningHall"
            value="rothschild"
            className="input-registration"
            checked={diningHall === "rothschild"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="rothschild"
            style={{ color: "#D6F6FF" }}
            className="diningHall-labels"
          >
            Rothschild
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="2301"
            name="diningHall"
            value="2301"
            className="input-registration"
            checked={diningHall === "2301"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="2301"
            style={{ color: "#E2CBF7" }}
            className="diningHall-labels"
          >
            2301
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="thePub"
            name="diningHall"
            value="thePub"
            className="input-registration"
            checked={diningHall === "thePub"}
            onChange={handleDiningHallChange}
          />
          <label
            htmlFor="thePub"
            style={{ color: "#D1BDFF" }}
            className="diningHall-labels"
          >
            The Pub
          </label>
        </div>

        <h3 className="h3-registration">Allergens:</h3>
        <label className="label-registration">
          <input
            type="checkbox"
            name="allergen"
            value="Peanut"
            className="input-registration"
            onChange={handleAllergenChange}
          />{" "}
          Peanut
        </label>
        <label className="label-registration">
          <input
            type="checkbox"
            name="allergen"
            value="Gluten"
            className="input-registration"
            onChange={handleAllergenChange}
          />{" "}
          Gluten
        </label>
        <label className="label-registration">
          <input
            type="checkbox"
            name="allergen"
            value="Dairy"
            className="input-registration"
            onChange={handleAllergenChange}
          />{" "}
          Dairy
        </label>
        <label className="label-registration">
          <input
            type="checkbox"
            name="allergen"
            value="Egg"
            className="input-registration"
            onChange={handleAllergenChange}
          />{" "}
          Egg
        </label>
        <label className="label-registration">
          <input
            type="checkbox"
            name="allergen"
            value="Fish"
            className="input-registration"
            onChange={handleAllergenChange}
          />{" "}
          Fish
        </label>
        <label className="label-registration">
          <input
            type="checkbox"
            name="allergen"
            value="Pork"
            className="input-registration"
            onChange={handleAllergenChange}
          />{" "}
          Pork
        </label>
      </section>

      <button
        type="submit"
        onClick={handleSignUp}
        className="button-registration"
      >
        Start Planning My Meals!
      </button>
    </div>
  );
}

export default SignUp;
