import React from "react";
import { Link } from "react-router-dom";
import "../styles/NutritionPopup.css";

const NutritionPopup = ({
  selectedItems,
  selectedFacility,
  selectedMeal,
  onClose,
}) => {
  const calculateTotals = () => {
    const totals = {
      "Calories Cal": 0,
      "Fat g": 0,
      "Fat %": 0,
      "Sat Fat g": 0,
      "Sat Fat %": 0,
      "Trans Fat g": 0,
      "Chol mg": 0,
      "Chol %": 0,
      "Sodium mg": 0,
      "Sodium %": 0,
      "Potassium mg": 0,
      "Potassium %": 0,
      "Carbs g": 0,
      "Carbs %": 0,
      "Dietary Fiber g": 0,
      "Dietary Fiber %": 0,
      "Sugars g": 0,
      "Protein g": 0,
      "Vit A %": 0,
      "Vit C %": 0,
      "Calcium %": 0,
      "Iron %": 0,
      "Vitamin D IU units %": 0,
    };

    selectedItems.forEach(item => {
      item.totalNutrition = { ...totals };

      item.nutrition.forEach(element => {
        let { elementName, displayAmount, DisplayPercentOfGoal } = element;
        // if displayAmount is not a number (e.g. less), set it to 0
        if (isNaN(parseFloat(displayAmount))) {
          displayAmount = 0;
        }

        switch (elementName) {
          case "ENERGY (KCAL)":
            item.totalNutrition["Calories Cal"] = Math.round(parseFloat(displayAmount) * item.quantity);
            break;
          case "TOTAL LIPID (FAT)":
            item.totalNutrition["Fat g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Fat %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "FATTY ACID TOTAL SATURATED":
            item.totalNutrition["Sat Fat g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Sat Fat %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "FATTY ACIDS TOTAL TRANS":
            item.totalNutrition["Trans Fat g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            break;
          case "CHOLESTEROL":
            item.totalNutrition["Chol mg"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Chol %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "SODIUM":
            item.totalNutrition["Sodium mg"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Sodium %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "POTASSIUM":
            item.totalNutrition["Potassium mg"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Potassium %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "CARBOHYDRATE BY DIFFERENCE":
            item.totalNutrition["Carbs g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Carbs %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "FIBER TOTAL DIETARY":
            item.totalNutrition["Dietary Fiber g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            item.totalNutrition["Dietary Fiber %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "SUGARS TOTAL INCLUDING NLEA":
            item.totalNutrition["Sugars g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            break;
          case "PROTEIN":
            item.totalNutrition["Protein g"] = Math.round(parseFloat(displayAmount) * item.quantity);
            break;
          case "VITAMIN A (IU)":
            item.totalNutrition["Vit A %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "VITAMIN C TOTAL ASCORBIC ACID":
            item.totalNutrition["Vit C %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "CALCIUM":
            item.totalNutrition["Calcium %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "IRON":
            item.totalNutrition["Iron %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          case "VITAMIN D (D2+D3)":
            item.totalNutrition["Vitamin D IU units %"] = Math.round(parseFloat(DisplayPercentOfGoal) * item.quantity);
            break;
          default:
            break;
        }
      });

      Object.keys(totals).forEach(key => {
        totals[key] += item.totalNutrition[key];
      });
    });

    return totals;
  };

  const totals = calculateTotals();

  return (
    <div className="nutrition-popup-container">
      {/* <button className="close-button" onClick={onClose}>
        Close
      </button> */}
      <h1>Nutrition Information</h1>
      <div className="nutrition-info-row">
        <table className="nutrition-table table table-responsive table-bordered">
          <thead className="nutrition-header-row">
            <tr>
              <th></th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Calories Cal</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Fat g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Fat %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Sat Fat g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Sat Fat %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Trans Fat g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Chol mg</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Chol %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Sodium mg</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Sodium %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Potassium mg</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Potassium %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Carbs g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Carbs %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Fiber g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Fiber %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Sugars g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Protein g</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Vit A %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Vit C %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Calcium %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Iron %</div>
              </th>
              <th className="nutrition-header-name align-bottom">
                <div className="nutrition-header-text">Vitamin D %</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="nutrition-group-row">
              <td colSpan="200" className="nutrition-group-text">
                {selectedFacility} - {new Date().toLocaleDateString()} -{" "}
                {selectedMeal}
              </td>
            </tr>
            {selectedItems.map((item, index) => (
              <tr key={index} className="nutrition-detail-row">
                <td className="item-name">{item.itemName} - {item.quantity} x {item.portionSize}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Calories Cal"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Fat g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Fat %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Sat Fat g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Sat Fat %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Trans Fat g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Chol mg"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Chol %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Sodium mg"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Sodium %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Potassium mg"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Potassium %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Carbs g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Carbs %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Dietary Fiber g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Dietary Fiber %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Sugars g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Protein g"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Vit A %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Vit C %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Calcium %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Iron %"]}</td>
                <td className="nutrition-grid-data align-middle">{item.totalNutrition["Vitamin D IU units %"]}</td>
              </tr>
            ))}
            <tr className="nutrition-group-row">
              <td colSpan="200" className="nutrition-group-text">
                Meal Summary
              </td>
            </tr>
            <tr className="nutrition-summary-row">
            <td></td>
              <td className="nutrition-grid-data align-middle">{totals["Calories Cal"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Fat g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Fat %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Sat Fat g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Sat Fat %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Trans Fat g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Chol mg"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Chol %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Sodium mg"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Sodium %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Potassium mg"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Potassium %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Carbs g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Carbs %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Dietary Fiber g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Dietary Fiber %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Sugars g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Protein g"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Vit A %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Vit C %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Calcium %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Iron %"]}</td>
              <td className="nutrition-grid-data align-middle">{totals["Vitamin D IU units %"]}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="action-row">
        <button className="gold-rect-button-bordered" onClick={onClose}>
          Back to tracker
        </button>
      </div>
    </div>
  );
};

export default NutritionPopup;
