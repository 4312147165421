import React from "react";
import "../styles/MenuItem.css";

const MenuItem = ({ item, quantity, portionSize, handleQuantityChange, handleAddItem }) => {
  return (
    <div className="item-row">
      <span className="item-name">{item}</span>
      <span className="action-group">
        <img
          src="./images/thumbs_down.png"
          alt="Thumbs Down"
          className="thumb"
        ></img>
        <img
          src="./images/thumbs_up.png"
          alt="Thumbs Up"
          className="thumb"
        ></img>
        <input
          type="number"
          value={quantity}
          onChange={(e) => handleQuantityChange(item, e.target.value)}
          className="quantity-input"
        />
        <span className="item-unit">{portionSize}</span>
        <button className="add-btn" onClick={handleAddItem}>+</button>
      </span>
    </div>
  );
};

export default MenuItem;
