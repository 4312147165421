import React, { useState } from 'react';
import "../styles/RecPopup.css";

const RecPopup = ({
  onClose,
  onRecommend,
  selections,
  setSelections,
  fetchUpdatedRecommendations,
  setAge,
  setGender,
  setWeight,
  setHeight,
  setGoal,
  setTargetDeficit,
  setActivityLevel,
  setLocation,
  setMealType
}) => {
  const [tempSelections, setTempSelections] = useState(selections);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTempSelections({ ...tempSelections, [name]: value });
    if (name === 'location') {
      setLocation(value);
    } else if (name === 'mealTime') {
      setMealType(value);
    }
  };
  

  const handleRecommend = () => {
    onRecommend(tempSelections);
    fetchUpdatedRecommendations();
    onClose(); // Close the popup
  };


  const handleAdvancedChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      setTempSelections({ ...tempSelections, [name]: value });
    }
  };

  const handleAdvancedRecommend = () => {
    // Set the main state in the Home component with the temporary selections
    setAge(tempSelections.age);
    setGender(tempSelections.gender);
    setWeight(tempSelections.weight);
    setHeight(tempSelections.height);
    setGoal(tempSelections.goal);
    setTargetDeficit(tempSelections.targetDeficit);
    setActivityLevel(tempSelections.activityLevel);

    onRecommend(tempSelections);
    fetchUpdatedRecommendations();
    onClose();
  };

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  return (
    <div className="popup-window">
      <div className="meal-selection-bar">
        <p>
          I want to eat
          <select
            name="foodType"
            className="selector"
            onChange={handleChange}
            value={tempSelections.foodType}
          >
            <option value="anything">anything</option>
            {/* Add more options as needed */}
          </select>
          at
          <select
            name="location"
            className="selector"
            onChange={handleChange}
            value={tempSelections.location}
          >
            <option value="Zeppos">Zeppos</option>
            <option value="E. Bronson Ingram">EBI</option>
            <option value="Rothschild">Rothschild</option>
            {/* Add more locations as needed */}
          </select>
          for
          <select
            name="mealTime"
            className="selector"
            onChange={handleChange}
            value={tempSelections.mealTime}
          >
            <option value="breakfast">breakfast</option>
            <option value="lunch">lunch</option>
            <option value="dinner">dinner</option>
          </select>
          
        </p>
        <button
          className="gold-round-button"
          onClick={toggleAdvancedSettings}
        >
          Advanced Settings
        </button>
        <button
          className="gold-round-button"
          onClick={handleRecommend}
        >
          Recommend
        </button>
      </div>
        {showAdvancedSettings && (
          <h2 style={{ color: "var(--color-gold)" }}> Temporarily Unavailable </h2>
          // <div className="advanced-settings">
          //   <label>Gender:</label>
          //   <select name="gender" onChange={handleAdvancedChange} value={tempSelections.gender}>
          //     <option value="male">Male</option>
          //     <option value="female">Female</option>
          //   </select>

          //   <label>Age:</label>
          //   <input
          //     type="number"
          //     name="age"
          //     value={tempSelections.age}
          //     onChange={handleAdvancedChange} />

          //   <label>Weight (lbs):</label>
          //   <input
          //     type="number"
          //     name="weight"
          //     value={tempSelections.weight}
          //     onChange={handleAdvancedChange} />
            
          //   <label>Height (in):</label>
          //   <input
          //     type="number"
          //     name="height"
          //     value={tempSelections.height}
          //     onChange={handleAdvancedChange} />
            
          //   <label>Goal:</label>
          //   <select name="goal" onChange={handleAdvancedChange} value={tempSelections.goal}>
          //     <option value="lose">Lose</option>
          //     <option value="maintain">Maintain</option>
          //     <option value="gain">Gain</option>
          //   </select>

          //   <label>Target Deficit (cal):</label>
          //   <input
          //     type="number"
          //     name="targetDeficit"
          //     value={tempSelections.targetDeficit}
          //     onChange={handleAdvancedChange} />

          //   <label>Activity Level:</label>
          //   <select name="activityLevel" onChange={handleAdvancedChange} value={tempSelections.activityLevel}>
          //     <option value="sedentary">Sedentary</option>
          //     <option value="light">Light</option>
          //     <option value="moderate">Moderate</option>
          //     <option value="active">Active</option>
          //     <option value="veryActive">Very Active</option>
          //   </select>
          // </div>
        )}
      <button className="cancel-button" onClick={onClose}>
      <img src="/images/cancel_button.png" alt="Cancel" />      </button>
    </div>
  );
};

export default RecPopup;
