import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, addCartToFirebase } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "../styles/MealTracking.css";
import MenuItem from "./MenuItem";
import NutritionPopup from "./NutritionPopup";

const MealTracking = ({
  showMealTrackPopup,
  onCloseMealTrackPopup,
  selectedDate,
  selectedLocation,
  selectedMealType,
}) => {
  const [menuData, setMenuData] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(selectedLocation);
  const [selectedMeal, setSelectedMeal] = useState(selectedMealType);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemQuantities, setItemQuantities] = useState({});
  const [showNutritionPopup, setShowNutritionPopup] = useState(false);
  const navigate = useNavigate();

  // Fetch menu data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      const collectionName = `${selectedDate}-menus`;
      const docRef = doc(db, collectionName, selectedFacility);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const fullMenu = docSnap.data();
        setMenuData(fullMenu[selectedMeal.toLowerCase()]);
      } else {
        console.log("No such document!");
        setMenuData([]);
      }
    };

    fetchData();
  }, [selectedFacility, selectedMeal, selectedDate]);

  const handleFacilityChange = (event) => {
    setSelectedFacility(event.target.value);
  };

  const handleMealChange = (event) => {
    setSelectedMeal(event.target.value.toLowerCase());
  };

  const handleAddCartToFirebase = async () => {
    await addCartToFirebase(selectedItems, selectedMeal, selectedDate);
    setSelectedItems([]);
    alert("Your meal has been successfully added to your tracker!");
    navigate("/PastMeals");
  };

  const handleAddItem = (itemName) => {
    const quantity = itemQuantities[itemName] || 0;

    if (quantity > 0) {
      // Find the full item data in menuData, including its nutrition info
      const itemData = menuData.find((item) => item.itemName === itemName);

      if (itemData) {
        const newItem = {
          itemName: itemName,
          quantity: quantity,
          nutrition: itemData.nutrition,
          portionSize: itemData.portionSize,
        };

        setSelectedItems((prevItems) => {
          const existingIndex = prevItems.findIndex(
            (item) => item.itemName === itemName
          );
          if (existingIndex >= 0) {
            // If item already exists, update its quantity
            const updatedItems = [...prevItems];
            updatedItems[existingIndex].quantity = quantity;
            return updatedItems;
          } else {
            // Item doesn't exist, add as new
            return [...prevItems, newItem];
          }
        });

        console.log("Item added to cart", newItem);
      } else {
        console.log("Item not found in menuData");
      }
    } else {
      alert("Please select a quantity before adding to the cart.");
    }
  };

  const handleDeleteItem = (itemName) => {
    setSelectedItems((currentItems) =>
      currentItems.filter((item) => item.itemName !== itemName)
    );
    // Additionally reset the quantity for the UI
    setItemQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemName]: 0,
    }));
  };

  return (
    <div>
      <div
        className={`meal-tracking-container ${
          showMealTrackPopup ? "" : "hidden"
        } ${showNutritionPopup ? "blur" : ""}`}
      >
        <div className="selectors-title-bar">
          <div className="selectors-col">
          <select value={selectedMeal} onChange={handleMealChange}>
            <option value="breakfast">Breakfast</option>
            <option value="lunch">Lunch</option>
            <option value="dinner">Dinner</option>
          </select>
          <select value={selectedFacility} onChange={handleFacilityChange}>
            {/* Ensure these match your backend data */}
            <option value="Zeppos">Zeppos</option>
            <option value="E. Bronson Ingram">EBI</option>
            {/* <option value="Kissam Production">Kissam</option> */}
            <option value="Rothschild">Rothschild</option>
          </select>
          </div>
          <div className="cart-title-col">
            <h2>My Meal</h2>
          </div>
          <button className="close-button" onClick={onCloseMealTrackPopup}>
            X
          </button>
        </div>
        <div className="content-row">
          <div className="menu-col">
            <div className="menu-items-list">
              {menuData.map((item, index) => (
                <MenuItem
                  key={index}
                  item={item.itemName}
                  portionSize={item.portionSize}
                  quantity={itemQuantities[item.itemName] || 0}
                  handleQuantityChange={(itemName, quantity) =>
                    setItemQuantities((prev) => ({
                      ...prev,
                      [itemName]: quantity,
                    }))
                  }
                  handleAddItem={() => handleAddItem(item.itemName)}
                />
              ))}
            </div>
          </div>
          <div className="cart-col">
            <ul className="cart-items-list">
              {selectedItems.map((item, index) => (
                <li key={index}>
                  {item.itemName} - Quantity: {item.quantity}
                  <button
                    onClick={() => handleDeleteItem(item.itemName)}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            <div className="btn-container">
              <button
                className="white-span-btn"
                onClick={() => setShowNutritionPopup(true)}
              >
                Check Nutrition
              </button>
              <button
                className="gold-span-btn"
                onClick={handleAddCartToFirebase}
              >
                Add to Tracker
              </button>
            </div>
          </div>
        </div>
      </div>
      {showNutritionPopup && (
        <NutritionPopup
          selectedItems={selectedItems}
          selectedFacility={selectedFacility}
          selectedMeal={selectedMeal}
          onClose={() => setShowNutritionPopup(false)}
        />
      )}
    </div>
  );
};

export default MealTracking;
