import React, { useEffect, useState } from "react";
import NavBar from "./components/NavBar";
import "./styles/Profile.css";
import { auth, updateUserNutritionTarget, getUserNutritionTarget, getUserInfo, getUserDiningInfo, updateUserDiningInfo } from "../firebase";
import { onAuthStateChanged } from 'firebase/auth';
import { set } from "firebase/database";

const Profile = () => {
  const [calories, setCalories] = useState(2000);
  const [protein, setProtein] = useState(100);
  const [carbs, setCarbs] = useState(300);
  const [fat, setFat] = useState(50);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [allergens, setAllergens] = useState([]);
  const [newAllergens, setNewAllergens] = useState([]); // For updating allergens
  const [diningHall, setDiningHall] = useState("noPreference");
  const [diningHallColor, setDiningHallColor] = useState("gray"); // Default color for no preference
  const [userNutritionTarget, setUserNutritionTarget] = useState({});
  const [userDiningInfo, setUserDiningInfo] = useState({});
  const [mealPreferences, setMealPreferences] = useState({
    Monday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
    Tuesday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
    Wednesday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
    Thursday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
    Friday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
    Saturday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
    Sunday: { Breakfast: "gray", Lunch: "gray", Dinner: "gray" },
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setName(user.displayName);
        setEmail(user.email);
        setUserNutritionTarget(getUserNutritionTarget(user.uid));
        setUserDiningInfo(getUserDiningInfo(user.uid));
      } else {
        // User is signed out
        setName(null);
        setEmail(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useState(() => {
    console.log("update", userNutritionTarget, userDiningInfo);
  }, [userNutritionTarget, userDiningInfo]);
  
  const fetchData = async () => {
    const userNutritionTarget = await getUserNutritionTarget();
    const userDiningInfo = await getUserDiningInfo();
    if (userNutritionTarget) {
      setCalories(userNutritionTarget.targetCalories);
      setProtein(userNutritionTarget.targetProtein);
      setCarbs(userNutritionTarget.targetCarbs);
      setFat(userNutritionTarget.targetFat);
    }
  
    if (userDiningInfo) {
      setAllergens(userDiningInfo["allergens"]);
      setNewAllergens(userDiningInfo["allergens"]);
      setMealPreferences(userDiningInfo["mealPreferences"]);
    }
  };

  useEffect(() => {
    fetchData();
  });

  const handleCellClick = (day, meal) => {
    if (!editMode) return;
    const updatedPreferences = { ...mealPreferences };
    updatedPreferences[day][meal] = diningHallColor;
    setMealPreferences(updatedPreferences);
  };

  const handleAllergenChange = (event) => {
    const allergenItem = event.target.value;
    if (event.target.checked) {
      setNewAllergens([...newAllergens, allergenItem]);
    } else {
      setNewAllergens(newAllergens.filter((item) => item !== allergenItem));
    }
  };

  const handleDiningHallChange = (event) => {
    const selectedHall = event.target.value;
    setDiningHall(selectedHall);
    switch (selectedHall) {
      case "noPref":
        setDiningHallColor("gray");
        break;
      case "rand":
        setDiningHallColor("#FA9189");
        break;
      case "kissam":
        setDiningHallColor("#FCAE7C");
        break;
      case "zeppos":
        setDiningHallColor("#FFE699");
        break;
      case "commons":
        setDiningHallColor("#e2f268");
        break;
      case "ebi":
        setDiningHallColor("#B3F5BC");
        break;
      case "rothschild":
        setDiningHallColor("#D6F6FF");
        break;
      case "2301":
        setDiningHallColor("#E2CBF7");
        break;
      case "thePub":
        setDiningHallColor("#D1BDFF");
        break;
      default:
        setDiningHallColor("gray");
    }
  };

  const lookupDiningHallByColor = (color) => {
    switch (color) {
      case "gray":
        return "No Preference";
      case "#FA9189":
        return "Rand";
      case "#FCAE7C":
        return "Kissam";
      case "#FFE699":
        return "Zeppos";
      case "#e2f268":
        return "Commons";
      case "#B3F5BC":
        return "EBI";
      case "#D6F6FF":
        return "Rothschild";
      case "#E2CBF7":
        return "2301";
      case "#D1BDFF":
        return "The Pub";
      default:
        return "No Preference";
    }
  };

  return (
    <div className="profile-page-container">
      <NavBar />
      <div className="profile-container">
        <div className="user-info-row">
        <span className="user-gravatar" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ccc',
          borderRadius: '50%',
          width: '50px',
          height: '50px'
        }}>
          {name && name[0].toUpperCase()}
        </span>
          <span className="user-info">
            <h2 >{name}</h2>
            <p>{email}</p>
          </span>
          <span className="button-container">
          </span>
        </div>
        <div className="goal-header-row">
          <h3>My Nutrition Goal:</h3>
          <p>
            Don't know how to calculate this? Take a short quiz
            <a href="https://tdeecalculator.net/">here</a>
          </p>
        </div>
        <div className="goal-inputs-row">
          <span className="nutrition-input-col">
            <p>Calories:
            <input
              type="number"
              value={calories}
              onChange={(e) => setCalories(e.target.value)}
              disabled={!editMode}
            />
            cal</p>
          </span>
          <span className="nutrition-input-col">
            <p>Protein:
            <input
              type="number"
              value={protein}
              onChange={(e) => setProtein(e.target.value)}
              disabled={!editMode}
            />
            g</p>
          </span>
          <span className="nutrition-input-col">
            <p>Carbs:
            <input
              type="number"
              value={carbs}
              onChange={(e) => setCarbs(e.target.value)}
              disabled={!editMode}
            />
            g</p>
          </span>
          <span className="nutrition-input-col">
            <p>Fat:
            <input
              type="number"
              value={fat}
              onChange={(e) => setFat(e.target.value)}
              disabled={!editMode}
            />
            g</p>
          </span>
          <span className="btn-col">
            { editMode ? 
              <button className="save-btn" onClick={() => {
                updateUserNutritionTarget(calories, protein, carbs, fat)
                updateUserDiningInfo(newAllergens, mealPreferences)
                console.log(allergens, newAllergens)
                alert("Edits saved!");
                setEditMode(false);
                fetchData();
              }}>
                Save
              </button> :
              <button className="edit-btn" onClick={() => setEditMode(true)}>
                Edit
              </button>}
          </span>
        </div>
        <div className="requirements-row">
          <span className="preferences-col">
            <div className="preferences-header-row">
              <h3>My Location Preferences:</h3>
            </div>
            <table className="preferences-table table table-responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th>Breakfast</th>
                    <th>Lunch</th>
                    <th>Dinner</th>
                  </tr>
                </thead>
                <tbody>
                {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => (
                    <tr key={day}>
                      <td className="td-registration">{day}</td>
                      <td
                        onClick={() => handleCellClick(day, "Breakfast")}
                        style={{
                          backgroundColor: mealPreferences[day]["Breakfast"],
                        }}
                        className="td-registration"
                      >{lookupDiningHallByColor(mealPreferences[day]["Breakfast"])}</td>
                      <td
                        onClick={() => handleCellClick(day, "Lunch")}
                        style={{
                          backgroundColor: mealPreferences[day]["Lunch"],
                        }}
                        className="td-registration"
                      >{lookupDiningHallByColor(mealPreferences[day]["Lunch"])}</td>
                      <td
                        onClick={() => handleCellClick(day, "Dinner")}
                        style={{
                          backgroundColor: mealPreferences[day]["Dinner"],
                        }}
                        className="td-registration"
                      >{lookupDiningHallByColor(mealPreferences[day]["Dinner"])}</td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </span>
          {editMode && (
            <span className="preferences-edit-col">
              <label className="label-registration" htmlFor="diningHall">
                Select Dining Hall:
              </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  id="noPreference"
                  name="diningHall"
                  className="input-registration"
                  value="noPreference"
                  checked={diningHall === "noPreference"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="noPreference"
                  style={{ color: "gray", whiteSpace: "nowrap"  }}
                  // className="diningHall-labels"
                >
                  No Preference
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="rand"
                  name="diningHall"
                  value="rand"
                  className="input-registration"
                  checked={diningHall === "rand"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="rand"
                  style={{ color: `#FA9189` }}
                  // className="diningHall-labels"
                >
                  Rand
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="kissam"
                  name="diningHall"
                  value="kissam"
                  className="input-registration"
                  checked={diningHall === "kissam"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="kissam"
                  style={{ color: "#FCAE7C" }}
                  // className="diningHall-labels"
                >
                  Kissam
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="zeppos"
                  name="diningHall"
                  value="zeppos"
                  className="input-registration"
                  checked={diningHall === "zeppos"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="zeppos"
                  style={{ color: "#FFE699" }}
                  // className="diningHall-labels"
                >
                  Zeppos
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="commons"
                  name="diningHall"
                  value="commons"
                  className="input-registration"
                  checked={diningHall === "commons"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="commons"
                  style={{ color: "#e2f268" }}
                  // className="diningHall-labels"
                >
                  Commons
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="ebi"
                  name="diningHall"
                  value="ebi"
                  className="input-registration"
                  checked={diningHall === "ebi"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="ebi"
                  style={{ color: "#B3F5BC" }}
                  // className="diningHall-labels"
                >
                  EBI
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="rothschild"
                  name="diningHall"
                  value="rothschild"
                  className="input-registration"
                  checked={diningHall === "rothschild"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="rothschild"
                  style={{ color: "#D6F6FF" }}
                  // className="diningHall-labels"
                >
                  Rothschild
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="2301"
                  name="diningHall"
                  value="2301"
                  className="input-registration"
                  checked={diningHall === "2301"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="2301"
                  style={{ color: "#E2CBF7" }}
                  // className="diningHall-labels"
                >
                  2301
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="thePub"
                  name="diningHall"
                  value="thePub"
                  className="input-registration"
                  checked={diningHall === "thePub"}
                  onChange={handleDiningHallChange}
                />
                <label
                  htmlFor="thePub"
                  style={{ color: "#D1BDFF" }}
                  // className="diningHall-labels"
                >
                  The Pub
                </label>
                </div>
            </span>)}
          <span className="restrictions-col">
            <h3>Dietary Restrictions:</h3>
              {editMode ? 
              <>
                <label className="label-registration">
                  <input
                    type="checkbox"
                    name="allergen"
                    value="Peanut"
                    className="input-registration"
                    onChange={handleAllergenChange}
                    defaultChecked={allergens.includes("Peanut")}
                  />{" "}
                  Peanut
                </label>
                <label className="label-registration">
                  <input
                    type="checkbox"
                    name="allergen"
                    value="Gluten"
                    className="input-registration"
                    onChange={handleAllergenChange}
                    defaultChecked={allergens.includes("Gluten")}
                  />{" "}
                  Gluten
                </label>
                <label className="label-registration">
                  <input
                    type="checkbox"
                    name="allergen"
                    value="Dairy"
                    className="input-registration"
                    onChange={handleAllergenChange}
                    defaultChecked={allergens.includes("Dairy")}
                  />{" "}
                  Dairy
                </label>
                <label className="label-registration">
                  <input
                    type="checkbox"
                    name="allergen"
                    value="Egg"
                    className="input-registration"
                    onChange={handleAllergenChange}
                    defaultChecked={allergens.includes("Egg")}
                  />{" "}
                  Egg
                </label>
                <label className="label-registration">
                  <input
                    type="checkbox"
                    name="allergen"
                    value="Fish"
                    className="input-registration"
                    onChange={handleAllergenChange}
                    defaultChecked={allergens.includes("Fish")}
                  />{" "}
                  Fish
                </label>
                <label className="label-registration">
                  <input
                    type="checkbox"
                    name="allergen"
                    value="Pork"
                    className="input-registration"
                    onChange={handleAllergenChange}
                    defaultChecked={allergens.includes("Pork")}
                  />{" "}
                  Pork
                </label>
              </> :
              <ul>
                {Array.isArray(allergens) && allergens.map((allergen) => (
                  <li key={allergen}>{allergen}</li>
                ))}
              </ul>}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Profile;
