import React from "react";
import "../styles/ProgressBar.css";

const ProgressBar = ({ label, value, max }) => {
  const percentage = (value / max) * 100;
  const isExceeded = value > max;

  return (
    <div className="progress-bar-row">
      <div className="progress-bar-label">{`${label}:`}</div>
      <div className="progress-bar" aria-label={`${label} progress`}>
        <div
          className={`progress-bar-fill ${isExceeded ? "exceeded" : "normal"}`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className="progress-bar-value">
        <text style={{ color: value > max ? "darkred" : "green" }}>{value}</text>/{max}
      </div>
    </div>
  );
};

export default ProgressBar;
