import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/NavBar.css';
import {isUserLoggedIn, auth, signOut} from '../../firebase'
import { onAuthStateChanged } from 'firebase/auth';

const NavBar = () => {
  const navigate = useNavigate();
  // const userName = isUserLoggedIn() ? auth.currentUser.displayName : null;
  const [userName, setUserName] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUserName(user.displayName);
      } else {
        // User is signed out
        setUserName(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  return (
    <nav className="navbar">
      <Link to="/" className="title-link">
        VandyFit
      </Link>
      <div>
        {isUserLoggedIn() ? (
          <>
            <div className="login-info-row">
              <text className="welcome-text">Welcome, {userName}</text>
            </div>
            <div className="link-row">
              <Link to="/home" className="navbar-link">
                Home
              </Link>
              <Link to="/pastmeals" className="navbar-link">
                Tracker
              </Link>
              <Link to="/profile" className="navbar-link">
                Profile
              </Link>
              <Link
                onClick={async () => {
                  await signOut();
                  console.log("signed out", auth.currentUser);
                  navigate("/");
                }}
                className="navbar-link"
              >
                Logout
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </nav>
  );
};

export default NavBar;
