import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import Background from './components/Background';
import './styles/Login.css';
import { signInWithGoogle, checkUserExistence, registerUser, isUserLoggedIn } from "../firebase.js";

function LoginPage() {
  let navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn()) {
      navigate("/home");
    }
  }, []);
  
  const handleGoogleLogin = async (event) => {
    event.preventDefault();
    const user = await signInWithGoogle();
    if (user) {
      const userExist = await checkUserExistence(user.email);
      const saveInfo = {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
      };
      localStorage.setItem("user", JSON.stringify(saveInfo));
  
      if (userExist) {
        navigate("/home");
      } else {
        const google_info = JSON.parse(localStorage.getItem("user"));
        const userinfo = {
          email: google_info.email,
          name: google_info.name,
          uid: google_info.uid,
        };
        registerUser(google_info.uid, userinfo);
        navigate("/signup");
      }
    } else {
      console.log("Google login failed");
    }
  };
  
  return (
    <div className='home-container'>
      <NavBar /> 
      <Background />
      <div className="welcome-message">
        Start tracking your meal nutrition today!
      </div>
        <div className="google-login-button">
          <button className= "loginButton"onClick={(event) => handleGoogleLogin(event)}>
            Login with Google
          </button>
        </div>
    </div>
  );
}

export default LoginPage;

